import React, { useState } from 'react';
import './TwoColumnLayout.css'; // External CSS file
import AnimatedButton from './AnimatedButton';

const About = ({targetDivRef}) => {
  // Sample images for the slider
  const images = [
    '/shopkeeper1.jpg',
    '/shopkeeper2.jpg',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + images.length) % images.length
    );
  };


  // Function to handle scroll to the target div
  const scrollToDiv = () => {
    if (targetDivRef && targetDivRef.current) {
        targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };

  const handleButtonClick = () => {
    console.log("Button clicked!"); // This will print in the browser console
  };

  return (
    <div className="container">
      {/* First column with text */}
      <div className="text-column">
        <h1>Welcome to Elwensoft</h1>
        <h2>
          "Empowering small towns in India with innovative digital solutions for better living."
        </h2>
        <br/>
        <AnimatedButton text="Explore projects" onClick={scrollToDiv}/>
      </div>

      {/* Second column with image slider */}
      <div className="slider-column">
        <div className="slider">
          <img
            src={images[currentImageIndex]}
            alt={`Slide ${currentImageIndex + 1}`}
            className="slider-image"
          />
          <div className="slider-controls">
            <button onClick={prevImage}>&lt;</button>
            <button onClick={nextImage}>&gt;</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;