import React from 'react';
import './Projects.css'
import '../App.css'

function Projects({targetDivRef}) {
  return (
    <section ref={targetDivRef} id="projects" className="project-div">
      <div className="product-section">
      <div className="product-header">
        <h2>Our Products</h2>
      </div>
      <div className="product-content">
        <div className="product-image-container">
          <div className="product-image" />
        </div>
        <div className="product-info">
          <h1 className="product-title">BulkLink</h1>
          <p className="product-description">
            BulkLink is a quick order solutions app for B2B businesses. Integrate your ERP like Tally with our app, enable your clients to place orders online and reduce the hassle of receiving orders on the phone.
          </p>
          <hr className="product-divider" />
          <div className="product-buttons">
            <button className="google-play-button">
              <img src="/googleplay.png" alt="Google Play" />
              <div className="button-text">
                <span>Get it on</span>
                <strong>Google Play</strong>
              </div>
            </button>
            <button
              className="privacy-policy-button"
              onClick={() => {
                window.location.href = '/bulklink-privacy-policy';
              }}
            >
              BulkLink Privacy Policy
            </button>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
}

  export default Projects;