// src/App.js
import {React, useRef} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './components/About';
import Projects from './components/Projects';
import Footer from './components/Footer';
import BulkLinkPrivacyPolicy from './components/BulkLinkPrivacyPolicy';

function App() {
  const targetDivRef = useRef(null);
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          {/* Define the routes for your different pages */}
          <Route path="/" element={
            <>
              <About targetDivRef={targetDivRef} />
              <Projects targetDivRef={targetDivRef} />
              <Footer />
            </>
          } />
          <Route path="/bulklink-privacy-policy" element={<BulkLinkPrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;