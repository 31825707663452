import React from 'react';

function Navbar() {
  return (
    <nav className="navbar">
        <img src='/elwenlogo.png' alt="Elwensoft-logo" style={{ width: '80px', height: '60px' }} />
      <h1 style={{ marginRight: '30px', marginLeft:'30px', color: '#06038D', fontFamily: "SFProDisplay" ,fontSize:28}}>Elwensoft</h1>
      {/* <ul>
        <li><a href="#about">About</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul> */}
    </nav>
  );
}

export default Navbar;